



















import { Vue, Component } from 'vue-property-decorator'
import ActivateForm from '@/components/set_password/ActivateForm.vue'
import { userAPI } from '@/api/user.api'
import { ActivateUserType, QueryDataType } from '@/types/user'
import { Message } from 'element-ui'

@Component({
  components: { ActivateForm },
})
export default class SetPassword extends Vue {
  queryData: QueryDataType = {
    confirmCode: '',
    userId: '',
  }

  async activateUser(activateData: ActivateUserType): Promise<void> {
    const [error, data] = await userAPI.activateUser({ ...activateData })

    if (!error && data) {
      await this.$router.push({ name: 'Login' })
    } else {
      Message({
        type: 'error',
        message: 'Произошла ошибка',
      })
    }
  }

  mounted(): void {
    this.queryData.confirmCode = this.$route.query.confirm_code as string
    this.queryData.userId = this.$route.query.user_id as string
  }
}
