








































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import InputCommon from '@/components/common/InputCommon.vue'
import { Dictionary } from 'vue-router/types/router'
import { FormRuleType } from '@/types/general'
import { ActivateUserType } from '@/types/user'
import { Form } from 'element-ui'

@Component({
  components: { InputCommon },
})
export default class ActivateForm extends Vue {
  $refs!: {
    activateFormRef: Form
  }

  @Prop({ type: Object })
  data!: Dictionary<string | (string | null)[]>

  @Emit()
  activateUser(activateForm: ActivateUserType): ActivateUserType | void {
    return activateForm
  }

  @Watch('data')
  setData(): void {
    this.form.confirmCode = this.data.confirm_code as string
    this.form.userId = +this.data.user_id
  }

  form: ActivateUserType = {
    userId: 0,
    password: '',
    passwordConfirm: '',
    confirmCode: '',
  }

  rules = {
    password: [
      {
        min: 6,
        required: true,
        message: 'Длина должна быть больше 6',
        trigger: 'change',
      },
      {
        validator: this.validatePassword,
        trigger: 'change',
      },
    ],
    passwordConfirm: [
      {
        validator: this.validateConfirmPassword,
        trigger: 'change',
      },
    ],
  }

  validatePassword(
    rule: FormRuleType,
    value: string,
    callback: Function
  ): void {
    const passwordRegularExpression = /(?=.*[a-z])(?=.*[A-Z])/g

    if (!passwordRegularExpression.test(value)) {
      callback(
        new Error(
          'Пароль должен содержать один символ верхнего и нижнего регистров'
        )
      )
    } else {
      callback()
    }
  }

  validateConfirmPassword(
    rule: FormRuleType,
    value: string,
    callback: Function
  ): void {
    if (value === '') {
      callback(new Error('Пожалуйста, введите пароль снова'))
    } else if (value !== this.form.password) {
      callback(new Error('Пароли не совпадают!'))
    } else {
      callback()
    }
  }

  handleSubmitForm(): void {
    this.$refs.activateFormRef.validate((valid) => {
      if (valid) {
        this.activateUser(this.form)
      }
    })
  }
}
